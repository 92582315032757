import React from 'react';
import * as styles from './ProductCollectionGrid.module.css';

import ProductCollection from '../ProductCollection';

const ProductCollectionGrid = (props) => {
  return (
    <div className={styles.root}>
      <ProductCollection
        image={'/collections/cake_toppers.jpg'}
        title={'Cake toppers'}
        text={'COTIZA'}
        link={'/shop'}
      />
      <ProductCollection
        image={'/collections/banners.jpg'}
        title={'Banners'}
        text={'COTIZA'}
        link={'/shop'}
      />
      <ProductCollection
        image={'/collections/recuerditos.jpg'}
        title={'Recuerditos'}
        text={'COTIZA'}
        link={'/shop'}
      />      <ProductCollection
        image={'/collections/vinil_textil.jpg'}
        title={'Vinil Textil'}
        text={'COTIZA'}
        link={'/shop'}
      />
      <ProductCollection
        image={'/collections/candy_bag.jpg'}
        title={'Candy Bag'}
        text={'COTIZA'}
        link={'/shop'}
      />
      <ProductCollection
        image={'/collections/dulceros.jpg'}
        title={'Dulceros'}
        text={'COTIZA'}
        link={'/shop'}
      />    </div>
  );
};

export default ProductCollectionGrid;
